export const assessmentTagline = {
  c: "Are your C++ skills good enough ? ",
  "data-structures-and-algorithms": "Data Structure And Algorithm",
  "programming-patterns": "Are your programming patterns good enough?",
  "object-oriented-programming": "Are your oops skill enough?",
  "unity-game-engine": "Are your unity game engine skills good enough? ",
};

export const buttonsModel = {
  start: { text: "Start", isDisabled: false },
  retry: { text: "Retry", isDisabled: false },
  upskill: { text: "Upskill", isDisabled: false },
  completed: { text: "Completed", isDisabled: true },
};

export const autoScrollStyles = {
  scaleRatio: 1.08,
  highlightColor: "lightYellow"
}
