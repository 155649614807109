import React from "react";
import {
  CTA,
  AssessmentImage,
  Root,
  Tag,
  Title,
  ImageWrapper,
  UpskillCTA,
  ScoreText,
} from "./AssessmentCard.styles";
import { ColDiv, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { autoScrollStyles } from "./AssessmentCard.model";

const AssessmentCardView = ({
  slug,
  title,
  displayName,
  imageUrl,
  questionsCount,
  ctaText,
  score,
  tagText,
  tagColors,
  showUpskillCTA,
  onAssessmentCardClick,
}) => {
  return (
    <Root
      id={`assessment-${slug}`}
      shadowColor={tagColors?.textColor}
      scaleRatio={autoScrollStyles?.scaleRatio}
      highlightColor={autoScrollStyles?.highlightColor}
      showUpskillCTA={showUpskillCTA}
      href={
        !score ? `/assessment/${slug}` : score < 80 ? "/courses#Courses" : "#"
      }
      onClick={onAssessmentCardClick}
      data-analytics={[UIElements.CARD, `${slug}-card-assessment`]}
      color={tagColors?.textColor}
    >
      <ColDiv>
        {tagText && (
          <Tag textColor={tagColors?.textColor} bgColor={tagColors?.bgColor}>
            {tagText}
          </Tag>
        )}
        <ImageWrapper color={tagColors?.textColor}>
          <AssessmentImage
            src={imageUrl}
            fill={true}
            alt={title}
            quality={1}
          />
        </ImageWrapper>
        <ColDiv gap="4px" padding="8px" alignItems="center">
          <Title>{displayName}</Title>
          <ScoreText
            textColor={tagColors?.textColor}
            fontWeight="semiBold"
            fontSize="sm"
          >
            {score ? `Score : ${score} / 100` : `${questionsCount} Questions`}
          </ScoreText>
        </ColDiv>
      </ColDiv>
      <RowDiv
        justifyContent="center"
        alignItems="center"
        gap="30px"
        margin="12px 0 0 0"
      >
        {showUpskillCTA && (
          <UpskillCTA
            data-analytics={[UIElements.LINK, `${slug}-upskill-assessment`]}
            href="/courses#Courses"
            onClick={(e) => e.stopPropagation()}
          >
            Upskill
          </UpskillCTA>
        )}
        {ctaText && (
          <CTA
            textColor={tagColors?.textColor}
            bgColor={tagColors?.bgColor}
            data-analytics={[UIElements.BUTTON, `${slug}-attempt-assessment`]}
          >
            {ctaText}
          </CTA>
        )}
      </RowDiv>
    </Root>
  );
};

export default AssessmentCardView;
