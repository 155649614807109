import styled from "styled-components";
import { Grid } from "@/outscal-commons-frontend/Styled/Grid";

export const Heading = styled.h2`
  font-size: ${(p) => p.theme.fontSize["5xl"]};
  text-align: center;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.xxxxl};
  }
`;

export const SubHeading = styled.p`
  font-size: ${(p) => p.theme.fontSize.lg};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  color: ${(p) => p.theme.colors.textDarkerGrey};
  text-align: center;
  margin-bottom: 40px;
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.md};
  }
`;

export const ListWrapper = styled(Grid)`
  margin-top: 30px;
  gap: 40px;
  grid-template-columns: 310px 310px 310px;
  justify-content: center;
  @media screen and (max-width: 1100px) {
    grid-template-columns: 360px 360px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: auto;
  }
`;