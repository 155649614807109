import React from "react";
import { sectionData } from "./AssessmentsList.model";
import AssessmentsListView from "./AssessmentsList.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";

const AssessmentsListController = ({
  data,
  section_id,
  failedAssessmentCTA,
  heading,
  subHeading,
  showOn,
}) => {
  const { assessments = [] } = data || {};
  return (
     <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="assessment">
      <AssessmentsListView
        id={section_id}
        heading={heading || sectionData?.heading}
        subHeading={subHeading || sectionData?.subHeading}
        assessments={assessments}
        failedAssessmentCTA={failedAssessmentCTA}
      />
    </section>
    </UseComponentVisibility>
  );
};

export default AssessmentsListController;
