import React from "react";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { Heading, ListWrapper, SubHeading } from "./AssessmentsList.styles";
import GridLayout from "@/mvcComponents/Commons/GridLayout/GridLayout.controller";
import AssessmentCardController from "../../Cards/AssessmentCard/AssessmentCard.controller";

const AssessmentsListView = ({
  id,
  assessments,
  failedAssessmentCTA,
  heading,
  subHeading,
}) => {
  return (
    <Div id={id}>
      <Heading>{heading}</Heading>
      <SubHeading>{subHeading}</SubHeading>
      <GridLayout
        col={3}
        minColCount={1}
        gridGap={40}
        cardComponent={AssessmentCardController}
        listData={assessments}
        customCardData={{ failedAssessmentCTA }}
        justifyContent="space-between"
      />
    </Div>
  );
};

export default AssessmentsListView;
