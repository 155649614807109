import Link from "next/link";
import Image from "next/image";
import styled, { css } from "styled-components";
import { Text } from "@/outscal-commons-frontend/Styled";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { YellowButton } from "@/outscal-commons-frontend/Styled/Buttons";
import { highlightAnimation } from "@/outscal-commons-frontend/Styled/Animations";

export const ImageWrapper = styled(Div)`
  position: relative;
  width: 100%;
  height: 180px;

  &::before {
    content: none;
  }
`;

export const Root = styled(Link)`
  min-height: 300px;
  background-color: ${(p) => p.theme.colors.white};
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: ${(p) => p.theme.borderRadius.xs};
  justify-content: space-between;
  transition: all 0.3s linear;
  position: relative;
  cursor: pointer;
  border: 2px solid ${(p) => p.theme.colors.lightGrey};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  ${css`
    &.isHighlighted {
      animation: ${({ scaleRatio, highlightColor }) =>
          highlightAnimation(scaleRatio, highlightColor)}
        1.2s ease;
    }
  `}

  &:hover {
    box-shadow: ${(p) =>
        p.shadowColor ? p.shadowColor : "0 14px 40px rgba(30, 10, 58, 0.1)"}
      0px 2px 8px 0px;
    scale: 1.03;
    border: 2px solid
      ${(p) => (p.shadowColor ? p.shadowColor : p.theme.colors.lightGrey)};
  }

  &:hover ${ImageWrapper}::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(p) => (p.color ? p.color : "rgba(0, 0, 0, 0)")};
    opacity: 0.5;
    border-radius: ${(p) => p.theme.borderRadius.xxs};
    z-index: 1;
  }
`;

export const AssessmentImage = styled(Image)`
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  object-fit: cover;
  position: relative;
`;

export const Title = styled.h3`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize.xl};
  font-weight: ${(p) => p.theme.fontWeight.medium};
`;

export const CTA = styled(YellowButton)`
  padding: 4px 16px;
  border: 2px solid ${(p) => p.theme.colors.themeYellow};
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  background-color: white;
`;

export const Tag = styled(Div)`
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 12px;
  background-color: ${(p) => p.bgColor};
  width: fit-content;
  color: ${(p) => p.textColor};
  padding: 4px 10px;
  border-bottom-left-radius: ${(p) => p.theme.borderRadius.xxxs};
  border-top-right-radius: ${(p) => p.theme.borderRadius.xxs};
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: ${(p) => p.theme.fontWeight.medium};
`;

export const ScoreText = styled(Text)`
  color: ${(p) => p.textColor || p.theme.colors.textDarkGrey};
`;

export const UpskillCTA = styled(Link)`
  background-color: ${(p) => p.theme.colors.themeYellow};
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  padding: 6px 16px;
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;
