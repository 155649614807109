import React, { useEffect, useState } from "react";
import AssessmentCardView from "./AssessmentCard.view";
import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import usePopupActions, {
  ASSESSMENT_POPUP,
  REGISTER_POPUP,
} from "@/store/actions/popupAction";
import useAuthActions from "@/store/actions/authAction";
import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";
import { assessmentTagline, buttonsModel } from "./AssessmentCard.model";
import { removeDecimals } from "@/utils/commonUtils";

const AssessmentCardController = ({ item, customCardData }) => {
  const { failedAssessmentCTA } = customCardData || {};
  const [tagText, setTagText] = useState();
  const [tagColors, setTagColors] = useState({});
  const [score, setScore] = useState(null);
  const { showPopup } = usePopupActions();
  const { auth } = useAuthActions();
  const [userAssessmentResponse, setUserAssessmentResponse] = useState(null);

  const getLatestResponse = async () => {
    try {
      const resp = await NetworkManager.get(
        `/assessment-response/getLatestResponse/${item._id}`
      );

      setUserAssessmentResponse(resp?.data);
    } catch (error) {
      console.log("error : ", error);
    }
  };

  useEffect(() => {
    if (auth.token) {
      getLatestResponse();
    } else {
      setUserAssessmentResponse(null);
    }
  }, [auth]);

  useEffect(() => {
    if (!userAssessmentResponse) {
      setScore(null);
      setTagText();
      setTagColors();
      return;
    }
    if (userAssessmentResponse?.total_score >= 0) {
      let text = "FAIL";
      let colors = {
        textColor: Theme.colors.deepRed,
        bgColor: Theme.colors.lightRed,
      };
      if (userAssessmentResponse?.total_score >= 80) {
        text = "PASS";
        colors.textColor = Theme.colors.textgreen;
        colors.bgColor = Theme.colors.lightBgGreen;
      }
      setTagColors(colors);
      setScore(removeDecimals(userAssessmentResponse?.total_score));
      setTagText(text);
    }
  }, [userAssessmentResponse]);

  const onAssessmentCardClick = (e, score) => {
    if (!score) {
      e.preventDefault();
      showPopup(ASSESSMENT_POPUP, {
        assessmentDisplayData: item,
        userAssessmentResponse,
        getLatestUserResponse: getLatestResponse,
        upskillCourseSlug: failedAssessmentCTA.slug,
        upskillCourseToShow: failedAssessmentCTA,
      });
    } else if (score > 80) {
      e.preventDefault();
    }
  };

  const {
    slug: assessmentSlug = "",
    image_url: assessmentImageUrl = "",
    display_name: assessmentDisplayName = "",
    number_of_questions: AssessmentQuesCount = 0,
  } = item || {};

  return (
    <AssessmentCardView
      slug={assessmentSlug}
      displayName={assessmentDisplayName}
      title={assessmentTagline[assessmentSlug]}
      imageUrl={assessmentImageUrl}
      questionsCount={AssessmentQuesCount}
      ctaText={score ? null : buttonsModel.start.text}
      score={score}
      tagText={tagText}
      tagColors={tagColors}
      showUpskillCTA={score && score < 80}
      onAssessmentCardClick={(e) => onAssessmentCardClick(e, score)}
    />
  );
};

export default AssessmentCardController;
